import Vue from 'vue';
import i18n from '@/plugins/i18n';
import '@/plugins/select';
import vuetify from '@/plugins/vuetify'

export const initWidget: (id: string, Widget: any) => void = (id, Widget) => {
  const element: HTMLElement | null = document.getElementById(`app-${id}`);
  const attributeNames = element?.getAttributeNames().filter(name => name !== 'id') || [];
  const mappedProps = attributeNames.reduce((p, attributeName) => {
    p[attributeName.replace('data-', '')] = element?.getAttribute(attributeName);
    return p;
  }, {} as any);

  if (['de', 'fr', 'it'].includes(mappedProps.language)) i18n.locale = mappedProps.language;
  new Vue({ i18n, vuetify, render: h => h(Widget, { props: { ...mappedProps } }) }).$mount(`#app-${id}`);
};
